// * front-end App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Projects from "./Pages/Projects";
import AboutPage from "./Pages/AboutPage";
import TextFormatPage from "./Pages/TextFormatPage";
import PageNotFound from "./Pages/PageNotFound";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="*"
					element={<PageNotFound />}
				/>
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/projects"
					element={<Projects />}
				/>
				<Route
					path="/contact"
					element={<Contact />}
				/>
				<Route
					path="/about"
					element={<AboutPage />}
				/>
				<Route
					path="/text-format"
					element={<TextFormatPage />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
