import React from "react";
import "../assets/styles/navbar.css";

const navbar = () => {
  return (
    <section id="mainNavbar">
      <div>
        <nav id="insideNav">
          <ul className="ulNav">
            <li className="liNav">
              <a className="hover-3 navLink" href="#title">
                Home
              </a>
            </li>
            <li className="liNav">
              <a className="hover-3 navLink" href="#mainSkillDiv">
                Skills
              </a>
            </li>
            <li className="liNav">
              <a className="hover-3 navLink" href="#ProjectSection">
                Projects
              </a>
            </li>
            <li className="liNav">
              <a className="hover-3 navLink" href="#contactSection">
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default navbar;
