import React from "react";
import "../assets/styles/projects.css";
import { Link } from "react-router-dom";

const ProjectCard = ({ image, title, description, link }) => {
	return (
		<div className="project-card">
			<div className="project-details">
				<Link to={link}>
					<h2 className="project-title text-3xl font-bold underline">{title}</h2>
				</Link>
				<p className="project-description">{description}</p>
			</div>
			<img
				src={image}
				alt={title}
				className="project-image"
			/>
		</div>
	);
};

export default ProjectCard;
