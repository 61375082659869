import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <section id="PageNotFoundSection" style={{ padding: "10% 0" }}>
        <div
          style={{ color: "#ffffff", fontSize: "50px", textAlign: "center" }}
        >
          Page Not Found !!!
        </div>
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <Link to="/">
            <button className="goBackToHome">Go Back</button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
