// import Axios from "axios";
import React, { useState } from "react";
import axios from "axios";
import "../assets/styles/contact.css";
import contactTeddyImage from "../assets/images/mailMe.gif";
import contactSectionImg from "../assets/images/contact-page-banner.webp";

const ContactMe = () => {
	const [user, setUser] = useState({
		name: "",
		Email: "",
		phone: "",
		Message: "",
	});

	let name, value;
	const handleInput = (e) => {
		name = e.target.name;
		value = e.target.value;
		setUser({ ...user, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("start");

		axios
			.post("http://localhost:3001/message", user)
			.then((response) => {
				console.log("Response:", response);
				// Reset the form values to their initial state
				console.log("1");
				setUser({
					name: "",
					Email: "",
					phone: "",
					Message: "",
				});
				console.log("Form cleared.");
			})
			.catch((error) => {
				console.error("Axios error:", error);
			});
	};

	return (
		<section id="contactSection">
			<div className="row">
				<div
					id="contactForm"
					className="col-lg-6">
					<div>
						<h3 id="contactFormHeading">Feel free to contact me...</h3>{" "}
						<img
							id="contactBoy"
							src={contactTeddyImage}
							alt="boy"></img>
					</div>
					<form onSubmit={handleSubmit}>
						<div className="inputField">
							<input
								type="text"
								name="name"
								id="fullName"
								placeholder="Full Name"
								value={user.name}
								onChange={handleInput}
								className="form-control form-control-sm"
								pattern="[A-Za-z ]{1,30}"
								required
							/>
						</div>
						<div className="inputField">
							<input
								type="email"
								name="Email"
								id="email"
								placeholder="Enter your email"
								value={user.Email}
								onChange={handleInput}
								required
								className="form-control form-control-sm"
							/>
						</div>
						<div className="inputField">
							<input
								type="text"
								name="phone"
								id="phone"
								placeholder="Enter Phone Number"
								value={user.phone}
								onChange={handleInput}
								className="form-control form-control-sm"
								pattern="[0-9]{1,10}"
								required
							/>
						</div>
						<div className="inputField">
							<textarea
								name="Message"
								id="floatingTextarea"
								placeholder="Type your message here"
								value={user.Message}
								onChange={handleInput}
								className="form-control form-control-sm"
								pattern=".{1,10}"
								rows="10"
							/>
						</div>
						<button
							type="submit"
							className="btn btn-outline-info messageBtn">
							Drop a Message
						</button>
					</form>
				</div>
				<div className="col-lg-6">
					<div id="ContactImgDiv">
						<img
							id="contactMeImg"
							src={contactSectionImg}
							alt="contact-me"
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactMe;
