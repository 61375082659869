import React from "react";
import Navbar from "../components/navbar";
import ContactSection from "../components/ContactSection";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <ContactSection />
    </div>
  );
};

export default Contact;
