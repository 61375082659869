import React from "react";

const ProgressBar = () => {
  return (
    <>
      <div className="progress-container">
        <div className="progress-bar" id="myBar"></div>
      </div>
    </>
  );
};

export default ProgressBar;
