import React from "react";
import Typewriter from "typewriter-effect";
import Footer from "../components/Footer";

const AboutPage = () => {
	return (
		<section id="aboutPage">
			<div id="aboutBody">
				<Typewriter
					options={{
						autoStart: true,
						loop: true,
						delay: 40,
						strings: ["Coming Soon..."],
					}}
				/>
			</div>
			<Footer />
		</section>
	);
};

export default AboutPage;
