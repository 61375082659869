import React from "react";
import Footer from "../components/Footer";
import "../assets/styles/text_format.css";
import BeforeClip from "../assets/icons/clipboard-regular.svg";
import AfterClip from "../assets/icons/clipboard-check-solid.svg";
import { Helmet } from "react-helmet";

function handleFormat(type) {
	let text = document.getElementById("text-to-format").value;
	switch (type) {
		case "uc":
			document.getElementById("formated-text").value = text.toUpperCase();
			document.getElementById("text-area").classList.remove("d-none");
			break;
		case "tc":
			const smallWords =
				/^(a|an|and|as|at|but|by|for|in|nor|of|on|or|so|the|to|up|yet)$/i;

			document.getElementById("formated-text").value = text
				.split(" ")
				.map((word) => {
					if (word.match(smallWords)) {
						return word.toLowerCase(); // Convert small words to lowercase
					}
					return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize first letter of other words
				})
				.join(" ");
			document.getElementById("text-area").classList.remove("d-none");
			break;
		case "lc":
			document.getElementById("formated-text").value = text.toLowerCase();
			document.getElementById("text-area").classList.remove("d-none");
			break;
		case "fl":
			document.getElementById("formated-text").value = text
				.split(" ")
				.map((word) => {
					return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
				})
				.join(" ");
			document.getElementById("text-area").classList.remove("d-none");
			break;
		case "sc":
			// Split paragraph
			let Sentences = text.split(/[.!?]/);
			let filteredSentences = Sentences.filter(
				(Sentence) => Sentence.trim() !== ""
			);
			const sentenceCaseSentences = filteredSentences.map((sentence) => {
				// Trim any leading or trailing spaces
				sentence = sentence.trim();

				// Capitalize the first letter of the sentence
				sentence =
					sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();

				return sentence;
			});
			const sentenceCaseParagraph = sentenceCaseSentences.join(". ") + ".";

			document.getElementById("formated-text").value = sentenceCaseParagraph;
			document.getElementById("text-area").classList.remove("d-none");
			break;
		default:
			return "Choose Right Option";
	}
}

function handleCopy() {
	navigator.clipboard.writeText(document.getElementById("formated-text").value);
	document.getElementById("gd-icon").src = AfterClip;
	setTimeout(function () {
		document.getElementById("gd-icon").src = BeforeClip;
	}, 4000);
}
const TextFormatPage = () => {
	return (
		<>
			<Helmet>
				<title>Portfolio | Text Format</title>
			</Helmet>
			<section id="text-format">
				<h1>Text Formater</h1>
				<div className="mt-3">
					<textarea
						rows="10"
						placeholder="Enter your text here"
						className="form-control"
						id="text-to-format"></textarea>
					<div id="text-format-btn-div">
						<button
							className="btn btn-info"
							onClick={() => handleFormat("uc")}>
							UPPER CASE
						</button>
						<button
							className="btn btn-info"
							onClick={() => handleFormat("tc")}>
							Title Case
						</button>
						<button
							className="btn btn-info"
							onClick={() => handleFormat("lc")}>
							lower case
						</button>
						<button
							className="btn btn-info"
							onClick={() => handleFormat("fl")}>
							First Letter Uppercase
						</button>
						<button
							className="btn btn-info"
							onClick={() => handleFormat("sc")}>
							Sentence case
						</button>
					</div>
				</div>
				<div
					id="text-area"
					className="d-none">
					<textarea
						rows="10"
						placeholder="Result text"
						className="form-control mt-3"
						id="formated-text"></textarea>
					<img
						src={BeforeClip}
						alt="clipboard"
						className="gd-icon"
						id="gd-icon"
						onClick={() => handleCopy()}></img>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default TextFormatPage;
