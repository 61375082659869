import React from "react";
// Animation
import { motion } from "framer-motion";
// External CSS File
import "../assets/styles/skills.css";
// All Images
import Js from "../assets/images/js.webp";
import ReactJsIcon from "../assets/images/ReactJsIcon.webp";
import Css3 from "../assets/images/css-3.webp";
import Git from "../assets/images/git.webp";
import Github from "../assets/images/github.webp";
import Visualstudio from "../assets/images/visual-studio.webp";
import Php from "../assets/images/php.webp";
import Codeigniter from "../assets/images/codeigniter.webp";
import Html5 from "../assets/images/html-5.webp";
import Sheets from "../assets/images/sheets.webp";
import Nodejs from "../assets/images/node-js.webp";
import ExpressJs from "../assets/images/express-js.webp";
import Mongodb from "../assets/images/mongodb.webp";
import ReactBootstrap from "../assets/images/react-bootstrap.webp";
import Docs from "../assets/images/docs.webp";
import Bootstrap from "../assets/images/bootstrap.webp";
import Laravel from "../assets/images/laravel.webp";
import Bitbucket from "../assets/images/bitbucket.webp";
import Wordpress from "../assets/images/wordpress.webp";

const SkillCards = () => {
  const skillImgs = {
    javascript: Js,
    reactJs: ReactJsIcon,
    bootstrap: Bootstrap,
    css: Css3,
    git: Git,
    github: Github,
    bitbucket: Bitbucket,
    visualStudio: Visualstudio,
    php: Php,
    wordpress: Wordpress,
    codeigniter: Codeigniter,
    laravel: Laravel,
    html5: Html5,
    sheets: Sheets,
    nodejs: Nodejs,
    expressjs: ExpressJs,
    mongodb: Mongodb,
    ractbootstrap: ReactBootstrap,
    doc: Docs,
  };

  const skillVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div id="mainSkillDiv">
      <section id="skillSection">
        <div>
          <h3 className="text-3xl text-white text-center mb-10">
            Skills/Technologies
          </h3>
        </div>
        <div className="flex-container">
          <div>
            {Object.entries(skillImgs).map(([key, value]) => (
              <motion.div
                key={key}
                className="skillDiv hvr-grow"
                initial="hidden"
                animate="visible"
                variants={skillVariants}
                data-toggle="tooltip"
                data-placement="top"
                title={key}
              >
                <img className="skillImg" src={value} alt={key} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SkillCards;
