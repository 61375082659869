import React from "react";
import "../assets/styles/review.css";
import cust1 from "../assets/images/man-img.webp";
import cust2 from "../assets/images/lady-img.webp";
import cust3 from "../assets/images/boy-img.webp";

const CustomerReview = () => {
	return (
		<section id="CustomerReviewSection">
			<div
				id="carouselExampleSlidesOnly"
				className="carousel slide main-carousel"
				data-bs-interval="false">
				<div className="carousel-inner">
					<div className="testimonials-item carousel-item active">
						<h2 className="testimonials-text">
							Extremely helpful, user-friendly, innovative and reliable, knowing
							that I can go back to them at any time for further help.
						</h2>
						<img
							className="testimonials-img"
							src={cust1}
							alt="dog-profile"
						/>
						<br />
						<em className="testimonial-name">Pebbles, New York</em>
					</div>
					<div className="testimonials-item carousel-item">
						<h2 className="testimonials-text">
							Simply fantastic and was one of the best choices we made when
							setting up our business!
						</h2>
						<img
							className="testimonials-img"
							src={cust2}
							alt="lady-profile"
						/>
						<br />
						<em className="testimonial-name">Beverly, Illinois</em>
					</div>
					<div className="testimonials-item carousel-item">
						<h2 className="testimonials-text">
							Efficient service. Seamless process from start to finish of
							website design.
						</h2>
						<img
							className="testimonials-img"
							src={cust3}
							alt="lady-profile"
						/>
						<br />
						<em className="testimonial-name">Mark, Nevada</em>
					</div>
				</div>
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExampleSlidesOnly"
					data-bs-slide="prev">
					<span
						className="carousel-control-prev-icon"
						aria-hidden="true"
					/>
					<span className="visually-hidden">Previous</span>
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target="#carouselExampleSlidesOnly"
					data-bs-slide="next">
					<span
						className="carousel-control-next-icon"
						aria-hidden="true"
					/>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</section>
	);
};

export default CustomerReview;
