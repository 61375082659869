import React, { useState } from "react";
import ProjectCard from "../components/ProjectCard";
import KalaniGroup from "../assets/images/projects/kalani-home-page.png";
import NHRSJO from "../assets/images/projects/nhrsjo.png";
import "../assets/styles/projects.css";

const Projects = () => {
  const projects = [
    {
      image: KalaniGroup,
      title: "Kalani Group Portfolio",
      description:
        "One stop solution for all end-to-end logistic needs.",
      link: "https://kalanigroup.in/"
    },
    {
      image: NHRSJO,
      title: "NHRSJO",
      description:
        "NHRSJO non-profit national human rights organization.",
      link: "https://nhrsjo.in/index.html"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex(
      currentIndex === 0 ? projects.length - 1 : currentIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex(
      currentIndex === projects.length - 1 ? 0 : currentIndex + 1
    );
  };

  return (
    <>
      <div id="ProjectSection">
        <div>
          <h2 className="text-3xl text-white text-center mb-2">Projects</h2>
        </div>
        <div className="carousel">
          <button onClick={prevSlide} className="carousel-button left">
            ‹
          </button>
          <div className="carousel-inner">
            {projects.map((project, index) => (
              <div
                className={`carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
                key={index}
              >
                <ProjectCard
                  image={project.image}
                  title={project.title}
                  description={project.description} 
                  link={project.link}
                />
              </div>
            ))}
          </div>
          <button onClick={nextSlide} className="carousel-button right">
            ›
          </button>
        </div>
      </div>
    </>
  );
};

export default Projects;
