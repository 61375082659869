import React from "react";
import "../assets/styles/footer.css";

const Footer = () => {
	const d = new Date();
	let year = d.getFullYear();
	return (
		<div>
			<footer id="footer">
				<div className="main-social-icons">
					<a
						className="social-icon-a"
						href="https://www.linkedin.com/in/heymrsourabh/"
						target="{_blank}">
						<i className="social-icon fa-brands fa-linkedin hvr-grow"></i>
					</a>
					<a
						className="social-icon-a"
						href="https://www.facebook.com/heyMr.Sourabh?mibextid=ZbWKwL"
						target="{_blank}">
						<i className="social-icon fa-brands fa-facebook-f hvr-grow"></i>
					</a>
					<a
						className="social-icon-a"
						href="https://instagram.com/hey_mr.sourabh?igshid=ZDdkNTZiNTM="
						target="{_blank}">
						<i className="social-icon fa-brands fa-instagram hvr-grow"></i>
					</a>
					<a
						onClick={() =>
							(window.location.href = "mailto:get.sourabhdas@gmail.com")
						}
						className="social-icon-a"
						href="mailto:get.sourabhdas@gmail.com">
						<i className="social-icon fa-solid fa-envelope hvr-grow"></i>
					</a>
					<a
						className="social-icon-a"
						href="https://github.com/sourabhongit?tab=repositories"
						target="{_blank}">
						<i className="social-icon fa-brands fa-github hvr-grow"></i>
					</a>
				</div>
				<p className="copyright-section">{year} © Copyright GeekDas</p>
			</footer>
		</div>
	);
};

export default Footer;
